<template>
  <footer
    id="footer"
    class="contain-content b-t b-grey-50 bg-white content-visibility-auto"
    style="contain-intrinsic-size: auto 100vw auto 830px;"
  >
    <div class="mx-a divide-y divide-grey-70" style="max-width: 90rem">
      <template v-if="enableLoyaltyFeatures && showLoyaltyContent">
        <section v-show="!isLoyaltyMember" class="px-4 py-8 text-center">
          <div>
            <h2 class="mb-2 title-3">
              {{ $t.footerContent.loyalty.header }}
            </h2>
            <p class="mb-6 text-sm md:mb-4 fw-light">
              {{ $t.footerContent.loyalty.description }}
            </p>
          </div>
          <vf-button
            :size="{ sm: 'sm', md: 'xs' }"
            class="c-white <md:w-full"
            :variant="loyaltyCtaTheme ? 'themable' : 'primary'"
            :theme="loyaltyCtaTheme"
            @click="ModalSignIn.open()"
          >
            {{ $t.footerContent.loyalty.button }}
          </vf-button>
        </section>
      </template>
      <form-newsletter v-else-if="!hideNewsletter" style="max-width: 28rem" class="mx-a py-8 <md:px-4" />
      <vf-footer-columns :footer-data="data" />
      <div class="md:mx-4 md:flex md:divide-x divide-grey-70 <md:divide-y">
        <div
          v-if="!hideNewsletter && enableLoyaltyFeatures && showLoyaltyContent"
          class="f-col flex-1 items-start px-4 between md:my-8 md:first:pl-0 <md:py-8"
        >
          <form-newsletter class="w-full" />
        </div>

        <section
          v-if="showStoreLocator"
          class="f-col items-start px-4 between md:my-8 md:first:pl-0 <md:py-8"
          :class="{
            'md:flex-row md:w-3/5 md:justify-evenly':
              !showLoyaltyContent && !showCustomizationContent,
            'flex-1': showLoyaltyContent || showCustomizationContent,
          }"
        >
          <div>
            <h2 class="mb-2 title-3">
              {{ $t.footerContent.storeLocator.header }}
            </h2>
            <p class="mb-6 text-sm md:mb-4 fw-light">
              {{ $t.footerContent.storeLocator.description }}
            </p>
          </div>
          <vf-button
            :size="{ sm: 'sm', md: 'xs' }"
            class="<md:w-full"
            to="/store-locator"
            @click="$gtm.push('footer.onClickFooterLink', 'Store Locator')"
          >
            {{ $t.footerContent.storeLocator.button }}
          </vf-button>
        </section>

        <section
          v-if="showSocialMediaLinks"
          class="flex-1 items-start px-4 between md:my-8 <md:py-8"
        >
          <div>
            <h2 class="mb-2 title-3">
              {{ $t.footerContent.social.header }}
            </h2>
            <div class="flex space-x-4 md:space-x-2">
              <div v-for="(to, name) in data?.links?.social" :key="name">
                <base-link :to :aria-label="name">
                  <vf-icon :name size="lg" />
                </base-link>
              </div>
            </div>
          </div>
        </section>
      </div>
      <vf-footer-legal-links v-if="data?.links?.legal" :links="data.links.legal" class="[&_i]:c-brand-4">
        <base-link to="/" class="<md:hidden">
          <vf-logo style="max-width: 7.5rem" loading="lazy" />
        </base-link>
      </vf-footer-legal-links>
    </div>
  </footer>
</template>

<script lang="ts" setup>
defineProps<{
  hideNewsletter?: boolean
}>()

const { data } = await useApi().internal.getFooter()
const { enableLoyaltyFeatures } = useFeatureFlags()

const {
  showCustomizationContent,
  showLoyaltyContent,
  showSocialMediaLinks,
  showStoreLocator,
  loyaltyCtaTheme
} = useAppConfig().components.vf.footer

const { ModalSignIn } = useDialogsStore()
const { isLoyaltyMember } = storeToRefs(useProfileStore())
</script>
